<template>
  <section id="dashboard-ecommerce">
    <div>
      <!-- Filters -->

      <!-- Table Container Card -->

      <b-card
        no-body
        class="mb-0"
      >
        <div
          class="text-center mt-5"
          style="color: #b4b7bd !important;"
        >

          <h3>
            Volunteer Stats
          </h3>
        </div>

        <b-card-body>
          <b-row>

            <b-col
              cols="4"
              md="4"
            >

              <label>Volunteer </label>
              <v-select

                v-model="volunteer_id"
                :options="volunteerOption"
                label="name"
                :reduce="(val) => val.id"
                :clearable="true"
              />
          
            </b-col>
            <b-col
              cols="6"
              md="6"
            >

              <label>  عدد  زيارات المتطوع </label>
             <h3> {{ visitCount }}</h3> 
            
             

              <label>  عدد إحالات المتطوع  </label>
             <h3> {{ referrallCount }}</h3> 
            </b-col>
          </b-row>
          <!-- apex chart -->
          <vue-apex-charts
            height="400"

            :options="chartOptionsVolunteer"
            :series="chartOptionsVolunteer.series"
          />
         

          <!-- chart info -->
        </b-card-body>
      </b-card>
      <hr>

      <b-card
        no-body
        class="mb-0"
      >
        <div
          class="text-center mt-5"
          style="color: #b4b7bd !important;"
        >

          <h3>
            طبيعة العائلة
          </h3>
        </div>

        <b-card-body>
          <b-row>

            <b-col
              cols="4"
              md="4"
            >

              <label>  طبيعة العائلة </label>
              <v-select

                v-model="familyStatus"
                :options="FamilyOptions"
                :reduce="(val) => val.value"
                :clearable="true"
              />

            </b-col>
            <b-col
              cols="6"
              md="6"
            >

              <label>   عدد العائلات المستفيدة </label>
             <h3> {{ FamilyNumber }}</h3> 
            </b-col>
          </b-row>
          <!-- apex chart -->
         
          <!-- chart info -->
        </b-card-body>
      </b-card>

      <hr>
      <b-card
        no-body
        class="mb-0"
      >
        <div
          class="text-center mt-5"
          style="color: #b4b7bd !important;"
        >

          <h3>
            الخدمة
          </h3>
        </div>

        <b-card-body>
          <b-row>

            <b-col
              cols="4"
              md="4"
            >

              <label>   الخدمة </label>
              <v-select

                v-model="mainService"
                :options="optionMainService"
                :reduce="(val) => val.value"
                :clearable="true"
              />

            </b-col>
            <b-col
              cols="6"
              md="6"
            >

              <label>   عدد العائلات المستفيدة </label>
             <h3> {{ mainServiceNumber }}</h3> 
            </b-col>
          </b-row>
          <!-- apex chart -->
         
          <!-- <vue-apex-charts
            height="400"

            :options="chartOptionsService"
            :series="chartOptionsService.series"
          /> -->

          <!-- chart info -->
        </b-card-body>
      </b-card>
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BCardHeader,
  BCardBody,
  BFormInvalidFeedback,
  bCardTitle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import VueApexCharts from 'vue-apexcharts'
import { ref, onUnmounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'

import 'flatpickr/dist/flatpickr.css'

export default {

  components: {
    VueApexCharts,

    bCardTitle,
    BButton,

    BDropdownItemButton,
    BFormInvalidFeedback,

    BCard,
    BRow,
    BCol,
    BFormInput,

    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      familyStatus: '',
      volunteerOption: [],
      FamilyOptions: [],
      mainService: '',
      optionMainService: [],
      date: '',
      durationType: '',
      roleFilter: '',
      employeFilter: '',
      chartOptionsService: {
        series: [
        ],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },

        noData: {
          text: 'No Data...',
        },

        labels: ['قيد الانتظار', 'مفتوحة', 'مغلقة', 'بانتظار الموافقة', 'حالة جديدة من قسم التسجيل', 'تم تحويلها'],

      },
      chartOptionsFamily: {
        series: [
        ],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },

        noData: {
          text: 'No Data...',
        },

        labels: ['قيد الانتظار', 'مفتوحة', 'مغلقة', 'بانتظار الموافقة', 'حالة جديدة من قسم التسجيل', 'تم تحويلها'],

      },
      chartOptionsVolunteer: {
        series: [
          {
            label: ' Volunteer ',
            data: [],
          }],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },

        dataLabels: {
          enabled: false,
        },
        noData: {
          text: 'No Data...',
        },
        labels: ['Volunteer Referrals Count ', 'Volunteer Visits Count  '],

      },
      chartOptions: {
        series: [],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#ffe700', '#FFA1A1'],

        labels: ['  الهدف  ', '  الانجاز  '],

        plotOptions: {
          pie: {

            donut: {
              labels: {
                show: true,

                total: {
                  show: true,
                  offsetY: 15,

                },
              },
            },
          },
        },

      },
      volunteer_id: '',
      personsPerSubService: [],
      mainServiceNumber: '',
      FamilyNumber: '',
      visitCount:'',
      referrallCount:'',
    }
  },
  watch: {

    mainService() {
      this.getmainService()
    },

    familyStatus() {
      this.getFamilyStats()
    },
    volunteer_id() {
      this.getVolunteerStats()
    },

  },
  created() {
    let today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = today.getFullYear()

    today = `${yyyy}-${mm}-${dd}`
    this.start_date = today

    this.date = today
    this.getVolunteerStats()
    this.getCoordinator()
  
  },
  methods: {
    getmainService() {
      const url = `/api/v1/orv/stats-by-main-service?main_service_id=${this.mainService}`
      this.$http.get(url).then(res => {
        window.dispatchEvent(new Event('resize'))
       // console.log(res.data)

        // this.chartOptionsVolunteer.xaxis.categories = res.data.data[0].stats.labels
        this.mainServiceNumber = res.data.data['Number of Families']
      })
    },
    getFamilyStats() {
      const url = `/api/v1/orv/stats-by-family-type?family_status_id=${this.familyStatus}`
      this.$http.get(url).then(res => {
        window.dispatchEvent(new Event('resize'))
    //    console.log(res.data.data["Number of Families "])

        this.FamilyNumber =res.data.data["Number of Families "]
        // this.chartOptionsVolunteer.series = res.data.data[0].stats.values
      })
    },
    async getVolunteerStats() {
      const url = `/api/v1/orv/stats-by-team?volunteer_id=${this.volunteer_id}`
      try {
        const response = await this.$http.get(url)
        const { data } = response.data
      //  console.log(data)
        this.visitCount = data['Volunteer Visits Count ']
      
        this.referrallCount = data['Volunteer Referrals Count ']
       

        // this.chartOptionsVolunteer.series[0].data = [
        //   data["Volunteer Referrals Count "],
        //   data["Volunteer Visits Count "]
        // ];
        this.chartOptionsVolunteer.series=[]
        this.chartOptionsVolunteer.series.push(data['Volunteer Referrals Count '])
        this.chartOptionsVolunteer.series.push(data['Volunteer Visits Count '])
       // console.log(' dataVolunteer Referrals Count :', data['Volunteer Referrals Count '])
        window.dispatchEvent(new Event('resize'))
       // console.log('f:', this.chartOptionsVolunteer.series)
      } catch (error) {
        console.error('Error fetching volunteer stats:', error)
      }
    },
    getCoordinator() {
      this.$http
        .get('/api/v1/volunteer')
        .then(res => {
          this.volunteerOption = res.data
        })
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const proposal_services = res.data.data.main_services
        // console.log(res.data.data.main_services)
        proposal_services.forEach(el => {
          this.optionMainService.push({ label: el.name, value: el.id })
        })
      })
      this.$http.get('/api/v1/family_statuses').then(res => {
        const FamilyOptions = res.data.data

        FamilyOptions.forEach(el => {
          this.FamilyOptions.push({ value: el.id, label: el.name })
        })
      })
    },

    getData() {
      window.dispatchEvent(new Event('resize'))
      this.$http.get(`/api/v1/achievement_target_stats?goal_type=${this.goalFilter}&date=${this.date}&is_summary=true&employee_id=${this.employeFilter}&duration_type=${this.durationType}
      `)
        .then(response => {
          window.dispatchEvent(new Event('resize'))
          // console.log("stat",response)
          // console.log("stat",response.data.total_goal)
          const data = []

          if (response.data) {
            data.push(parseInt(response.data.inserted_families_stats.total_goal))
            data.push(parseInt(response.data.inserted_families_stats.total_inserted_families))
            this.chartOptions.series = data
            window.dispatchEvent(new Event('resize'))
          }
        })
    },
  },
}

</script>

<style lang="scss">
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
